import {Observable} from 'rxjs'
import {ArticleLanguageAvailability, ArticlePage, TopicListItem, TopicOverviewPage} from '../types/article.types'
import {map} from 'rxjs/operators'
import {pickProperty} from '../../graphql/graphql-utils'
import {TranslocoService} from '@ngneat/transloco'
import {fromSiteLocale, getSiteLocale} from '../site-locale'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content-graphql-split'
import {LinkService} from '../../i18n/link.service'
import {
  GetArticleAuthorNamesGql,
  GetArticlePageAvailableLocalesGql,
  GetArticlePageGql,
  GetTopicListGql,
  GetTopicOverviewPageGql,
} from '../../../../__generated__/datocms.types'
import {parseArticlePage, parseTopicListItem, parseTopicOverviewPage} from '../serde/article-page.serde'
import {Injectable} from '@angular/core'
import {sanitizeSlug} from '../sanitize-slug'

@Injectable()
export class ArticleContentService {
  constructor(
    private readonly locale: TranslocoService,
    private readonly linkService: LinkService,
    private readonly getTopicListGql: GetTopicListGql,
    private readonly getTopicPageBySlugGql: GetTopicOverviewPageGql,
    private readonly getArticlePageGql: GetArticlePageGql,
    private readonly getArticleAuthorNamesGql: GetArticleAuthorNamesGql,
    private readonly getArticlePageAvailableLocalesGql: GetArticlePageAvailableLocalesGql,
  ) {}

  getArticlePage(pageSlug: string): Observable<ArticlePage> {
    const locale = getSiteLocale(this.locale)
    return this.getArticlePageGql
      .fetch({locale, pageSlug: sanitizeSlug(pageSlug)}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}})
      .pipe(
        map(res => pickProperty(res.data, 'articleModel')),
        map(page => {
          // filter out topics that aren't available in the current locale
          let topic = page.topic
          if (!topic?._locales.includes(locale)) {
            topic = undefined
          }
          return {...page, topic}
        }),
        map(page => parseArticlePage(page, this.linkService)),
      )
  }

  getArticlePageLanguageAvailability(pageSlug: string): Observable<ArticleLanguageAvailability> {
    return this.getArticlePageAvailableLocalesGql
      .fetch({pageSlug: sanitizeSlug(pageSlug)}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}})
      .pipe(
        map(res => pickProperty(res.data, 'articleModel')),
        map(res => ({
          slug: pageSlug,
          activeLocales: res._locales.map(fromSiteLocale),
        })),
      )
  }

  /**
   * Returns (up to 30) topic overview items, sorted by rank ASC
   */
  getTopicList(): Observable<TopicListItem[]> {
    return this.getTopicListGql
      .fetch({locale: getSiteLocale(this.locale), count: 30}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}})
      .pipe(
        map(res => pickProperty(res.data, 'allTopicOverviewPageModels')),
        map(res => res?.filter(i => !!i).map(i => parseTopicListItem(i!, this.linkService)) ?? []),
      )
  }

  getTopicOverviewPage(pageSlug: string): Observable<TopicOverviewPage> {
    const currentLocale = getSiteLocale(this.locale)
    return this.getTopicPageBySlugGql
      .fetch({locale: currentLocale, pageSlug, moreTopicCount: 50}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}})
      .pipe(
        map(res => ({
          page: pickProperty(res.data, 'topicOverviewPageModel'),
          moreTopics: pickProperty(res.data, 'moreTopics'),
        })),
        map(({page, moreTopics}) => parseTopicOverviewPage(page, moreTopics, this.linkService)),
      )
  }

  getAllArticleAuthorNames(): Observable<string[]> {
    return this.getArticleAuthorNamesGql.fetch(undefined, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}}).pipe(
      map(res => pickProperty(res.data, 'allAuthorModels')),
      map(authors => authors.map(a => a.name).filter((name): name is string => !!name)),
    )
  }
}
