import {DatoCmsGifPipe} from '../../../shared/pipes/datocms.pipe'

// https://www.datocms.com/features/images-api
// basically a wrapper around imgix
export function transformDatocmsImageUrl(imageSrc: string, args?: {width?: number}) {
  const isGif = new DatoCmsGifPipe().transform(imageSrc)
  const url = new URL(imageSrc)

  // resize the image if a width is provided
  if (args?.width) {
    url.searchParams.set('w', args.width.toFixed())
  }

  // If we get a gif from DatoCMS, we want to use jpg as a format to get better SEO performance
  if (isGif) {
    url.searchParams.set('fm', 'jpg')
    url.searchParams.append('auto', 'compress')
  }
  // for non GIF images, we want to use the imgix auto parameter to determine the best format and compress the image
  else {
    // auto is the only parameter that can be combined by reiteration and we must use it, because datocms doesn't support the comma separated format
    url.searchParams.append('auto', 'format')
    url.searchParams.append('auto', 'compress')
  }

  return url.toString()
}
